import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotNavigationBar from "./components/notNavigation";
import Home from "./pages/Home";
import Letstalk from "./pages/films";
import Categories from "./pages/categories";
import PerfectPitch from "./pages/perfectpitech";

function App() {

  return (
    <Router>
      <NotNavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/films" element={<Letstalk />} />
        <Route path="/aboutus" element={<Home />} />
        <Route path="/film" element={<Home />} />
        <Route path="/contactus" element={<Home />} />
        <Route path="/perfect-pitch" element={<PerfectPitch />} />
        <Route path="/categories/:path" element={<Categories />} />
      </Routes>
    </Router>
  );
}

export default App;
