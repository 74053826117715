import React, { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import star from "../assets/icons/star.png";
import spring from "../assets/icons/spring.png";
import comma from "../assets/icons/Layer 2 (2).png";
import comma_blue from "../assets/icons/Asset 20 1.svg";
import blue_ring from "../assets/icons/blue_ring.svg";
import home1 from "../assets/icons/Property 1=Frame 12.png";
import home2 from "../assets/icons/Property 1=Frame 11.png";
import zigzag from "../assets/icons/Layer 2 (1).png";
import zigzag_blue from "../assets/icons/Layer 3.svg";
import curvearrow from "../assets/icons/Layer 2 (4).svg";
import our_service_left_image from "../assets/icons/Vector.png";
import our_service_top_image from "../assets/icons/Vector (2).png";
import our_service_rightOne_image from "../assets/icons/Vector (3).png";
import our_service_rightTwo_image from "../assets/icons/Clip path group.svg";
import our_service_arrow_image from "../assets/icons/arrow_our_services.svg";
// import our_services_image from "../assets/icons/over_services_sm.svg";
import our_services_image from "../assets/icons/yellovexx.svg";
// arrow_our_services
// Layer 3
import ring from "../assets/icons/Asset 19 1.png";
import bottomarrow from "../assets/icons/Asset 10 1.png";
import doublebottomarrow from "../assets/icons/Layer 2 (3).png";
import doublebottomarrow_blue from "../assets/icons/Asset 15 2 (1).svg";
import swing from "../assets/icons/Asset 4 1.svg";
// import lets_talk_bg from "../assets/icons/let's_talk_bg.png";
// doublebottomarrow_blue
import Component1 from "../assets/icons/AUTOMOBILES 1 1.jpg";
import Component2 from "../assets/icons/Frame 59t.png";
import Component3 from "../assets/icons/CGI  1.jpg";
import Component4 from "../assets/icons/FASHION 1.png";
import Component5 from "../assets/icons/FOOD AND APPLIANCES  1.jpg";
import Component6 from "../assets/icons/SPORTS  1.jpg";
import sampleNarrative from "../assets/icons/Narravtive_mobile.png";
import sampleAutomotive from "../assets/icons/AUTOMOBILES_mobile.png";
import sampleCGI from "../assets/icons/CGI_mobile.png";
import sampleFashion from "../assets/icons/FASHION 2.png";
import sampleFood from "../assets/icons/FOOD AND APPLIANCES_mobile.png";
import sampleSport from "../assets/icons/SPORTS_mobile.png";
import image1 from "../assets/icons/partner12.png";
import image2 from "../assets/icons/partner2.png";
import "../index.css";
import "aos/dist/aos.css";
import Footer from "../components/footer";
import whiteLogo from "../assets/icons/pp logo svg.svg";
import { Link } from "react-router-dom";
import logo_video from "../assets/icons/logo_video.mp4";
import logo_gif from "../assets/icons/logo_gif.gif";
import { useLocation } from "react-router-dom";

const Home = () => {
  const scrollRef = useRef(null);
  const compRef = useRef(null);
  const letsRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/aboutus") {
      compRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (location.pathname === "/film") {
      letsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const stickyElements = scrollRef.current.querySelectorAll(".sticky");
      stickyElements.forEach((element) => {
        const top = element.getBoundingClientRect().top;
        let height = window.screen.height <= 850;
        const offset = 50;
        if (height && element.id === "samples") {
          if (top <= -175) {
            element.classList.add("fixed", "-top-[175px]");
          } else {
            element.classList.remove("fixed", "-top-[155px]");
          }
        } else {
          if (top <= offset) {
            element.classList.add("fixed", "top-0");
          } else {
            element.classList.remove("fixed", "top-0");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    var video = document.getElementById("videoPlayer");
    video
      .play()
      .then(function () {})
      .catch(function (error) {});
  });

  return (
    <div>
      <div className="hidden md:block GRIFTERBold700 gap-x-20">
        <div className="flex justify-center w-full bg-[#f5f4f6]">
          <video
            id="videoPlayer"
            src={logo_video}
            type="video/mp4"
            className="md:w-[90%] w-[100%]"
            loop
            autoPlay
            muted
          ></video>
        </div>
        <div>
          <div className="bg-yellove marquee flex justify-center font-semibold text-4xl focus:scroll-auto  text-white py-2 -mb-2">
            <div className="flex w-full gap-2">
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
            </div>
          </div>
          <div className="bg-hero-pattern w-full h-full">
            <div className="flex pt-8 lg:pl-20 justify-start">
              <div className="text-left p-4">
                <div className="flex items-center">
                  <div className="flex-col">
                    <img src={star} alt="star" className="mb-4" />
                    <h1 className="md:pl-4 text-center text-white font-bold md:text-[56px] md:leading-[56px] text-4xl">
                      Hello, from
                      <br />
                      Pitch Perfekt
                    </h1>
                  </div>
                </div>
                <div className="flex justify-between">
                  <span className="sr-only">text to be hidden</span>
                  <div
                    className="text-right md:hidden flex items-center justify-end"
                    style={{ position: "absolute", right: 0 }}
                  >
                    <img
                      src={doublebottomarrow}
                      alt="img"
                      className="md:hidden pb-20 md:pr-10"
                    />
                    <img
                      src={bottomarrow}
                      alt="img"
                      className="pt-20 md:w-20 w-20"
                    />
                    <div className="relative">
                      <h1 className="text-center text-[#F5B352] font-bold md:text-[56px] md:leading-[56px] text-4xl">
                        Step into
                        <br /> the world
                      </h1>
                      <img src={home1} alt="" className="absolute -mt-4" />
                    </div>
                    <img
                      src={zigzag}
                      alt="zigzag"
                      className="float-right bg-blend-luminosity"
                    />
                  </div>
                </div>

                <div className="flex items-center md:mt-20 md:mb-20 mt-42 lg:pl-40 w-max">
                  <img
                    src={doublebottomarrow}
                    alt="img"
                    className="hidden md:block pb-20 md:pr-10"
                  />
                  <div className="relative  mt-40">
                    <h1 className="text-center text-[#F5B352] font-bold  md:text-[56px] text-4xl md:leading-[56px] ">
                      Your vision,
                      <br /> our craft!
                    </h1>
                    <img
                      src={home2}
                      alt=""
                      className="absolute -mt-8 md:ml-20 ml-6 md:w-80 md:hover:w-96 hover:w-64 duration-700"
                    />
                  </div>
                </div>
              </div>
              <div className="flex  items-center flex-col md:mt-4">
                <div className="absolute real-image right-0 hidden md:block"></div>
                <div className="relative  mt-12 flex w-1/5 object-right text-center mb-20 hidden md:block">
                  <img src={comma} alt="" className=" ml-20" />
                </div>

                {/* <div className="hidden md:flex items-center w-max pr-20">
                <img src={bottomarrow} alt="img" className="pt-20 pr-10 lg:w-48 md:w-80 w-10" /> */}
                <div className="md:w-full hidden md:flex ">
                  {/* <div className="hidden md:flex items-center lg:pr-20 pr-1"> */}
                  <div className="md:w-2/5 lg:w-1/3 f;ex items-center justify-center">
                    {/* <img src={bottomarrow} alt="img" className="lg:mt-8 md:mt-12 pt-10 md:rotate-12 lg:rotate-[27px] lg:w-[17rem] lg:h-[13rem] md:w-20 w-[13rem] h-[7rem]" /> */}
                    <img
                      src={bottomarrow}
                      alt="img"
                      className="lg:mt-8 md:mt-12 pt-10 md:rotate-12 lg:rotate-[27px] lg:w-[20rem] lg:h-[13rem] md:w-20 w-[13rem] h-[7rem]"
                    />
                  </div>
                  <div className="md:w-2/5 relative mb-20 lg:ml-0 md:ml-20">
                    <h1 className="text-center text-[#F5B352] font-bold md:text-[56px] md:leading-[56px] text-4xl">
                      Step into the world
                    </h1>
                    <img
                      src={home1}
                      alt=""
                      className="absolute -mt-8 lg:ml-8 lg:w-80 lg:hover:w-88 md:w-72 md:hover:w-80 md:duration-500 hover:w-64 duration-500"
                    />
                  </div>
                  <div className="flex justify-end h-full relative xl:ml-[20vh] lg:ml-[10vh]">
                    <div className="">
                      <img
                        src={zigzag}
                        alt="zigzag"
                        className="absolute bg-blend-luminosity h-12 w-12 relative mt-52"
                      />
                    </div>
                  </div>
                  {/* <div className="w-full relative">
              <img
                src={zigzag}
                alt="zigzag"
                className="absolute bg-blend-luminosity relative bottom-0"
                position= "bottom-0"
              />
              </div> */}
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              {/* <img src={real} alt="real" className="hidden md:block" /> */}
              {/* hidden md:block mix-blend-multiply bg-[#4A588E] cover */}
              <div className="blend-image hidden md:block" />
              {/* <div className="flex  items-center flex-col mt-12"> */}
              <div className="hidden md:block text-center mt-52 -ml-72">
                <img src={ring} alt="" />
              </div>
              {/* </div> */}
              {/* <div className="flex items-center -mt-20"> */}
              <div className="flex md:items-center justify-end items-end md:-mt-20 ml-20">
                <img
                  src={spring}
                  alt="img"
                  className="pb-40 md:w-32 w-16 rotate-180 md:mt-5 mb-6"
                />
                <div className="relative md:mr-12 mb-12">
                  <h1 className="text-center text-white font-bold  md:text-[56px] md:leading-[56px] text-4xl">
                    Together, we <br /> can create
                  </h1>
                  {/* <h1
                  className="text-center font-bold md:text-[60px] md:leading-[60px] text-4xl  bg-gradient-to-b from-[#F5B352] to-[#FF5B5B] inline-block text-transparent bg-clip-text transition-colors drop-shadow-[0_5px_5px_#111827] duration-500"
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundImage =
                      "linear-gradient(to bottom, #FF5B5B, #F5B352)")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundImage =
                      "linear-gradient(to bottom, #F5B352, #FF5B5B)")
                  }
                > */}
                  <p className="text-center font-bold text-transparent md:text-[60px] md:leading-[60px] text-5xl  bg-gradient-to-b from-[#F5B352] to-[#FF5B5B] bg-clip-text drop-shadow-[0_5px_5px_#111827] hover:from-[#FF5B5B] hover:to-[#F5B352] duration-500">
                    MAGIC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between bg-yellove">
          {/* <div> */}
          <img
            src={our_service_left_image}
            alt="our_service_left_image"
            className="mr-4 max-h-full"
            style={{ width: "10%" }}
            // lg:h-[110vh] h-[20vh]
          />
          
          {/* <div className="parent-div relative"> */}
          <div className="hidden xl:block -mr-[23%]">
  <img src={our_service_top_image} alt="" className="h-40 lg:h-88" />
  </div>
{/* </div> */}
          {/* </div> */}
          <div className="flex flex-col justify-center items-center 2xl:-ml-[17%] xl:-ml-[4%] gap-8">
            <div className="w-full justify-start items-start ">
          <img src={our_service_top_image} alt="" className="xl:hidden   block h-40 lg:h-88 " />
          </div>

            <div className="xl:mt-4 flex flex-col justify-around items-center">
              <p className="-mt-4 pb-6 text-center font-normal font_blogh text-[#C75092] text-6xl leading-[65.92px]">
                Our Services
              </p>
              <p className="pb-4 text-center ttMusscelsTRL text-[#FFF] lg:text-[30px] text-[20px] leading-[45.92px] font-bold">
                Visual Research | Creative Writing | Layout Designing | AI Visual Referencing{" "}
              </p>
              <p className="text-center ttMusscelsTRL text-[#FFF] lg:text-[30px] text-[20px] leading-[45.92px] font-bold">
                Brand Film Treatment Note | Feature Film Bible | Music Video
                Lookbook{" "}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <img src={our_service_rightOne_image} alt="" className="lg:h-[25vh] xl:[45vw]" />
            <div>
            <img src={our_service_rightTwo_image} alt="" />
            <img src={our_service_arrow_image} alt="" className="w-16 -ml-10" />
            </div>
          </div>
        </div>
        <div className="hidden md:flex items-center p-4 bg-white-noise">
          <div className="w-[10%] relative hidden md:flex justify-center">
            <h1 className="font_blogh md:mr-0 mr-8 text text-[#F5B352] font-bold lg:text-9xl md:text-8xl text-4xl  flip-verticle -rotate-90 scale-105 translate-x-4">
              SAMPLES
            </h1>
          </div>
          <div className="w-[40%] h-full relative md:hidden flex justify-center items-center flip-verticle -rotate-90 scale-105 translate-x-4">
            <p className="md:mr-4 mb-10 text-[#F5B352] font-bold text-[117px]">
              Categories
            </p>
          </div>
          <div className="md:w-[90%] w-[60%] " id="categoriesUs">
            <div className="grid grid-cols-6 md:gap-10">
              <div className="col-span-6 grid grid-cols-6">
                <Link
                  className="group relative text-[#C75092] hover:text-white  md:col-span-3 col-span-6 md:row-span-2 py-3 automotiveDiv"
                  to="/categories/Narrative"
                >
                  <div className="automotive_left_div"></div>
                  <div className="automotive_right_div"></div>
                  <div className="automotive_top_div"></div>
                  <div className="automotive_bottom_div"></div>
                  <img
                    src={Component2}
                    alt="Narrative"
                    className="md:h-full h-28 object-contain border-2 border-[#C75092] rounded-2xl"
                  />
                  <p
                    className="font_blogh absolute text-left text_bottom_all px-3 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                    style={{}}
                  >
                    NARRATIVE
                  </p>
                  <img
                    alt="pitch-perket-white-logo"
                    className="absolute md:bottom-[3rem] md:bottom-9 bottom-1 px-4 pt-4 pb-0 right-2  image_size md:text-5xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                    src={whiteLogo}
                  />
                </Link>
                <Link
                  to="/categories/Automotive"
                  className="rounded-md relative text-[#4A588E] hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 narrativeDiv"
                >
                  <div className="narrative_left_div"></div>
                  <div className="narrative_right_div"></div>
                  <div className="narrative_top_div"></div>
                  <div className="narrative_bottom_div"></div>
                  <img
                    src={Component1}
                    alt="AUTOMOTIVE"
                    className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                  />
                  <p
                    className="font_blogh absolute text-left text_bottom_all px-4 pt-8 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                    style={{}}
                  >
                    AUTOMOTIVE
                  </p>
                  <img
                    alt="pitch-perket-white-logo"
                    className="absolute md:bottom-[2.7rem] bottom-1 px-4 pt-4 pb-0 image_size right-2 font-bold drop-shadow-xl font-bold whitelogohover"
                    src={whiteLogo}
                  />
                </Link>
                <Link
                  to="/categories/CGI: VFX"
                  className=" relative  text-[#F5B352] hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 cgiDiv"
                >
                  <div className="cgi_left_div"></div>
                  <div className="cgi_right_div"></div>
                  <div className="cgi_top_div"></div>
                  <div className="cgi_bottom_div"></div>
                  <img
                    src={Component3}
                    alt=""
                    className="md:h-full h-28  w-full border-2 border-[#F5B352] rounded-2xl"
                  />
                  <p
                    className="font_blogh absolute text-left text_bottom_two px-4 pt-8 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                    style={{}}
                  >
                    CGI-VFX
                  </p>
                  <img
                    alt="pitch-perket-white-logo"
                    className="absolute md:bottom-[2.7rem] bottom-1 px-4 pt-4 image_size pb-0 right-2 font-bold drop-shadow-xl font-bold whitelogohover"
                    src={whiteLogo}
                  />
                </Link>
                <div className="grid grid-cols-6 col-span-6 gap-4">
                  <Link
                    to="/categories/Fashion & Beauty"
                    className=" relative  text-[#4A588E] hover:text-white md:col-span-2 col-span-6 fashionDiv"
                  >
                    <div className="fashion_left_div"></div>
                    <div className="fashion_right_div"></div>
                    <div className="fashion_top_div"></div>
                    <div className="fashion_bottom_div"></div>
                    <img
                      src={Component4}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left text_bottom_three px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      FASHION
                      <br />& BEAUTY
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-16 bottom-1 px-4 pt-4 image_size_three pb-0 right-2 font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <Link
                    to="/categories/Food & Appliance"
                    className="relative  text-[#F5B352] hover:text-white md:col-span-2 col-span-6 foodDiv"
                  >
                    <div className="food_left_div"></div>
                    <div className="food_right_div"></div>
                    <div className="food_top_div"></div>
                    <div className="food_bottom_div"></div>
                    <img
                      src={Component5}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left text_bottom_three px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      FOOD & <br />
                      APPLIANCES
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-16 image_size_three bottom-1 px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <Link
                    to="/categories/Sports & Games"
                    className="relative  text-[#C75092] hover:text-white md:col-span-2 col-span-6 sportDiv"
                  >
                    <div className="sport_left_div"></div>
                    <div className="sport_right_div"></div>
                    <div className="sport_top_div"></div>
                    <div className="sport_bottom_div"></div>
                    <img
                      src={Component6}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute  text-left text_bottom_three px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      SPORTS
                      <br />& GAMES
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-16 image_size_three  px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden flex items-center p-4 bg-black-noise">
          <div className="w-[40%] h-full relative md:hidden flex justify-center items-center flip-verticle -rotate-90 scale-105 translate-x-4">
            <p className="font_blogh md:mr-4 mb-10 text-[#F5B352] font-bold text-[117px]">
              Categories
            </p>
          </div>
          <div className="lg:w-[80%] w-[60%] " id="categoriesUs">
            <div className="grid grid-cols-6 md:gap-10">
              <div className="col-span-6 grid grid-cols-6">
                <Link
                  to="/categories/Narrative"
                  className="rounded-md relative text-[#4A588E] hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 narrativeDiv"
                >
                  <div className="narrative_left_div"></div>
                  <div className="narrative_right_div"></div>
                  <div className="narrative_top_div"></div>
                  <div className="narrative_bottom_div"></div>
                  <img
                    src={Component2}
                    alt=""
                    className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                  />
                  <p
                    className="font_blogh absolute text-left md:bottom-5 bottom-1 p-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                    style={{}}
                  >
                    NARRATIVE
                  </p>
                  <img
                    alt="pitch-perket-white-logo"
                    className="absolute md:bottom-9 bottom-1 p-4 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                    src={whiteLogo}
                  />
                </Link>
                <Link
                  className="group relative text-[#C75092] hover:text-white  md:col-span-3 col-span-6 md:row-span-2 py-3 automotiveDiv"
                  to="/categories/Automotive"
                >
                  <div className="automotive_left_div"></div>
                  <div className="automotive_right_div"></div>
                  <div className="automotive_top_div"></div>
                  <div className="automotive_bottom_div"></div>
                  <img
                    src={Component1}
                    alt="Automotive"
                    className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                  />
                  <p
                    className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-2 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                    style={{}}
                  >
                    AUTOMOTIVE
                  </p>
                  <img
                    alt="pitch-perket-white-logo"
                    className="absolute md:bottom-9 bottom-1 px-4 pt-4 pb-2 right-2 md:text-5xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                    src={whiteLogo}
                  />
                </Link>
                <Link
                  to="/categories/CGI: VFX"
                  className=" relative  text-[#F5B352] hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 cgiDiv"
                >
                  <div className="cgi_left_div"></div>
                  <div className="cgi_right_div"></div>
                  <div className="cgi_top_div"></div>
                  <div className="cgi_bottom_div"></div>
                  <img
                    src={Component3}
                    alt=""
                    className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                  />
                  <p
                    className="font_blogh absolute text-left md:bottom-5 bottom-1 p-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                    style={{}}
                  >
                    CGI-VFX
                  </p>
                  <img
                    alt="pitch-perket-white-logo"
                    className="absolute md:bottom-9 bottom-1 p-4 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                    src={whiteLogo}
                  />
                </Link>
                <div className="grid grid-cols-6 col-span-6 gap-4">
                  <Link
                    to="/categories/Fashion & Beauty"
                    className=" relative  text-[#4A588E] hover:text-white md:col-span-2 col-span-6 fashionDiv"
                  >
                    <div className="fashion_left_div"></div>
                    <div className="fashion_right_div"></div>
                    <div className="fashion_top_div"></div>
                    <div className="fashion_bottom_div"></div>
                    <img
                      src={Component4}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left md:bottom-5 bottom-1 p-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      FASHION
                      <br />& BEAUTY
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-20 bottom-1 p-4 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <Link
                    to="/categories/Food & Appliance"
                    className="relative  text-[#F5B352] hover:text-white md:col-span-2 col-span-6 foodDiv"
                  >
                    <div className="food_left_div"></div>
                    <div className="food_right_div"></div>
                    <div className="food_top_div"></div>
                    <div className="food_bottom_div"></div>
                    <img
                      src={Component5}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left md:bottom-5 bottom-1 p-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      FOOD & APPLIANCES
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-20 bottom-1 p-4 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <Link
                    to="/categories/Sports & Games"
                    className="relative  text-[#C75092] hover:text-white md:col-span-2 col-span-6 sportDiv"
                  >
                    <div className="sport_left_div"></div>
                    <div className="sport_right_div"></div>
                    <div className="sport_top_div"></div>
                    <div className="sport_bottom_div"></div>
                    <img
                      src={Component6}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left md:bottom-5 bottom-1 p-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      SPORTS
                      <br />& GAMES
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-20 bottom-1 p-4 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-pink-noice px-8 py-8 md:px-20" id="aboutus">
          <div className=" pb-8 text-left">
            <h1 className="md:mb-4 text-[#F5B352] font_blogh  text-4xl font-bold leading-103">
              What’s Pitch Perfekt?
            </h1>
            <p className="text-justify text-white ttMusscelsTRL text-xl font-bold leading-9 tracking-[.05em]">
              Pitch Perfekt is where creativity meets impact.{" "}
              <span className="text-[#F5B352] ttMusscelsTRL text-xl font-bold leading-6">
                {" "}
                Specializing in crafting exceptional pitch decks and treatment
                notes,{" "}
              </span>
               we’re dedicated to turning the client’s vision into reality. Our
              team collaborates closely with clients, ensuring that each
              presentation is not just visually appealing but also rich in
              storytelling. From the initial concept to the final pitch, we
              function as an extended arm for directors, production houses &
              agencies, {" "}
              <span className="text-[#F5B352] ttMusscelsTRL text-xl font-bold leading-6">
              making sure that your brand & film stands out.
              </span>{" "}
              With Pitch Perfekt, you’re choosing a partner committed to making
              every moment count and every slide sell.{" "}
            </p>
          </div>
          <div className=" pb-8 text-left">
            <h1 className="text-[#F5B352] md:mb-4 font_blogh text-4xl font-bold leading-103">
              Why Choose Us?
            </h1>
            <p className="text-justify text-white ttMusscelsTRL text-xl font-bold leading-9">
              Choosing Pitch Perfekt means opting for a partner who understands
              the importance of nourished storytelling and visual appeal in
              presentations. Our services are tailored to{" "}
              <span className="text-[#F5B352] ttMusscelsTRL text-xl font-bold leading-6">
                showcase your vision in the brightest light,
              </span>{" "}
              ensuring that your pitches are not only seen but also remembered.
            </p>
          </div>
        </div>
        <div className="bg-our_story_md GRIFTERBold700 w-full h-full">
          <div className="pt-10 flex items-center justify-center">
            <p className="text-7xl font-normal text-[#4A588E] font_blogh">
              Our Founders
            </p>
            <img src={zigzag_blue} alt="zigzag_blue" className="-mt-12" />
          </div>

          <div className="flex pt-10">
            <div className="h-full">
              <div className="w-full mt-24 flex justify-center items-center">
                <img
                  src={doublebottomarrow_blue}
                  alt="img"
                  className=" mb-28 md:mb-[14rem] lg:mb-28"
                />
              </div>
              <img
                alt="amir-bashir"
                src={image1}
                className="absoulte bottom-0 w-[137rem] h-[30rem] xl:h-[30rem] 2xl:h-[30rem] lg:mt-44 md:mt-[24rem] mt-[21rem]"
              />
            </div>
            <div className=" xl:text-xl text-justify DemiBoldttMusscelsTRL text-base text-[#4A4A4A] text-justify leading-[32px] xl:leading-[32px] -ml-12 -mr-16 z-10">
              <p className="z-10">
                Our founders, Amir and Nimish, have been companions on an
                extraordinary journey through the dynamic world of filmmaking
                and creativity. With over five years of experience in the
                industry, their path has been one of growth, learning, and
                shared passion.
                <br />
                <br />
                From their humble beginnings as in-house Director’s Assistants
                to their current roles as freelancers and partners, they’ve
                embraced every challenge and opportunity with enthusiasm and
                dedication. Nimish brings a unique flair for style and visual
                storytelling, while Amir’s strength lies in crafting compelling
                narratives. Their pitch decks range across various categories –
                Narratives, Fashion & Beauty, Beverages, Food, Sports, Games,
                Automobile, VFX and CGI.
                <br />
                <br />
                Their experience-enriched journey combined with their fervent
                ambition for growth and perfection in the industry has led to
                the creation of Pitch Perfekt—a culmination of their collective
                experiences and aspirations. With a commitment to excellence and
                a thorough understanding of industry work dynamics, Pitch
                Perfekt aims to elevate the art of pitching to new heights.
                <br />
                <br />
                So, here’s to the journey ahead—to the pitches we’ll perfect,
                the stories we’ll tell, and the magic we’ll create together.
              </p>
              <div className="flex justify-between">
                <div className="3xl:mt-20 2.5xl:mt-12 lg:mt-1 md:mt-8 mt-64 w-full flex flex-col">
                  <div className="flex items-center justify-start">
                    <img src={swing} alt="" />
                  </div>
                  <div className="flex  -mr-[48px] 2xl:ml-[20%] items-center 2xl:justify-start justify-end">
                    <p className="flex xl:text-4xl text-2xl font_blogh text-[#C75092]">
                      Amir Bashir{" "}
                      <span className="hidden lg:block">&nbsp; Dar</span>
                    </p>
                    <img src={star} alt="star" className="-mt-12  w-6" />
                  </div>
                </div>
                <div className="3xl:mt-60 2.5xl:mt-28 lg:mt-16 md:mt-40 mt-96 w-full flex flex-col">
                  <div className="flex items-center justify-end  lg:mr-[12px]">
                    <img src={curvearrow} alt="" className="w-32" />
                  </div>
                  <div className="flex 2xl:-ml-[96px] -ml-[64px]  items-center 2xl:justify-center  justify-center">
                    <img
                      src={blue_ring}
                      alt="star"
                      className="-mt-8 mr-2 w-6"
                    />
                    <p className="flex xl:text-4xl text-2xl font_blogh text-[#C75092]">
                      Nimish{" "}
                      <span className="hidden lg:block">&nbsp; Poyrekar </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full">
              <div className="w-full mt-32 flex justify-center items-center ">
                <img src={comma_blue} alt="img" className="mt-48 -mr-40" />
                {/* mb-28 md:mb-[14rem] */}
                {/* className=" md:mb-28 mb-[14rem]" */}
              </div>
              <img
                alt="nimish"
                src={image2}
                className="absoulte bottom_large w-[137rem] h-[30rem] xl:h-[30rem] 2xl:h-[30rem] lg:mt-16 md:mt-[24rem] mt-[21rem]"
              />
              {/* <img
                alt="amir-bashir"
                src={image2}
                className="absoulte bottom-0 w-[137rem] h-[30rem] xl:h-[30rem] 2xl:h-[30rem] lg:mt-64 md:mt-[40rem] mt-96"
              /> */}
            </div>
          </div>
        </div>
        <div className="marquee flex font-semibold text-4xl focus:scroll-auto bg-yellove text-white py-2 -mb-2">
          <div className="flex w-full gap-2">
            <h1>THE SLIDE THAT SELLS .</h1>
            <h1>THE SLIDE THAT SELLS .</h1>
            <h1>THE SLIDE THAT SELLS .</h1>
            <h1>THE SLIDE THAT SELLS .</h1>
            <h1>THE SLIDE THAT SELLS .</h1>
          </div>
        </div>
        <Footer />
      </div>
      <div ref={scrollRef} className="md:hidden w-full">
        <div className="sticky bg-white shadow-md">
          <div className="flex items-center justify-center bg-[#f5f4f6] w-full h-full my-auto">
            {/* <video id="videoPlayer" src={logo_gif} type="video/mp4" className="md:w-full w-full h-full" loop autoPlay muted></video> */}
            <img
              src={logo_gif}
              alt="video"
              className="md:w-full w-full h-full"
            />
          </div>
        </div>
        <div className="sticky bg-white shadow-md mb-4 h-screen">
          <div className="bg-yellove marquee flex justify-center font-semibold text-xl focus:scroll-auto  text-white py-2 -mb-2">
            <div className="flex w-full gap-2">
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
            </div>
          </div>
          <div className="bg-hero-pattern w-full h-full">
            <div className="flex pt-8 lg:pl-20 justify-start">
              <div className="text-left p-4">
                <div className="flex items-center">
                  <div className="flex-col">
                    <img src={star} alt="star" className="mb-4" />
                    <h1 className="md:pl-4 text-center text-white font-bold md:text-[56px] md:leading-[56px] text-4xl">
                      Hello, from
                      <br />
                      Pitch Perfekt
                    </h1>
                  </div>
                </div>
                <div className="flex justify-between">
                  <span className="sr-only">text to be hidden</span>
                  <div
                    className="text-right md:hidden flex items-center justify-end"
                    style={{ position: "absolute", right: 0 }}
                  >
                    <img
                      src={doublebottomarrow}
                      alt="img"
                      className="md:hidden pb-20 md:pr-10"
                    />
                    <img
                      src={bottomarrow}
                      alt="img"
                      className="pt-20 md:w-20 w-20"
                    />
                    <div className="relative">
                      <h1 className="text-center text-[#F5B352] font-bold md:text-[56px] md:leading-[56px] text-4xl">
                        Step into
                        <br /> the world
                      </h1>
                      <img src={home1} alt="" className="absolute -mt-4" />
                    </div>
                    <img
                      src={zigzag}
                      alt="zigzag"
                      className="float-right bg-blend-luminosity"
                    />
                  </div>
                </div>

                <div className="flex items-center md:mt-20 md:mb-20 mt-42 lg:pl-40 w-max">
                  <img
                    src={doublebottomarrow}
                    alt="img"
                    className="hidden md:block pb-20 md:pr-10"
                  />
                  <div className="relative  mt-40">
                    <h1 className="text-center text-[#F5B352] font-bold  md:text-[56px] text-4xl md:leading-[56px] ">
                      Your vision,
                      <br /> our craft!
                    </h1>
                    <img
                      src={home2}
                      alt=""
                      className="absolute -mt-4 md:ml-20 ml-6 sm: w-72 w-52 duration-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex  items-center flex-col md:mt-4">
                <div className="absolute real-image right-0 hidden md:block"></div>
                <div className="relative  mt-12 flex w-1/5 object-right text-center mb-20 hidden md:block">
                  <img src={comma} alt="" className=" ml-20" />
                </div>
                <div className="hidden md:flex ">
                  <img
                    src={bottomarrow}
                    alt="img"
                    className="pt-10 rotate-12 lg:w-[7rem] lg:h-[13rem] md:w-20 w-[13rem] h-[7rem]"
                  />
                  <div className="relative mb-20 md:ml-20">
                    <h1 className="text-center text-[#F5B352] font-bold md:text-[56px] md:leading-[56px] text-4xl">
                      Step into a
                      <br /> World
                    </h1>
                    <img
                      src={home1}
                      alt=""
                      className="absolute -mt-8 lg:ml-8 md:w-72 md:hover:w-80 md:duration-500 hover:w-64 duration-500"
                    />
                  </div>
                  <div className="h-full relative ml-18 mt-20 pt-10">
                    <div className="lg:px-8">
                      <img
                        src={zigzag}
                        alt="zigzag"
                        className="absolute bg-blend-luminosity h-12 w-12 relative mt-52"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="blend-image hidden md:block" />
              <div className="hidden md:block text-center mt-52 -ml-72">
                <img src={ring} alt="" />
              </div>
              <div className="flex md:items-center justify-end items-end md:-mt-20 ml-20">
                <img
                  src={spring}
                  alt="img"
                  className="pb-40 md:w-32 w-16 rotate-180 md:mt-5 mb-6"
                />
                <div className="relative md:mr-12 mb-12">
                  <h1 className="text-center text-white font-bold  md:text-[56px] md:leading-[56px] text-4xl">
                    Together, we <br /> can create
                  </h1>
                  <p className="text-center font-bold text-transparent md:text-[60px] md:leading-[60px] text-5xl  bg-gradient-to-b from-[#F5B352] to-[#FF5B5B] bg-clip-text drop-shadow-[0_5px_5px_#111827] hover:from-[#FF5B5B] hover:to-[#F5B352] duration-500">
                    MAGIC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky shadow-md h-full">
          <div className="flex justify-between bg-yellove">
            <img
              src={our_services_image}
              alt=""
              className="sm:hidden w-full "
            />
            <div className="hidden sm:flex justify-between w-full">
              <img
                src={our_service_left_image}
                alt="our_service_left_image"
                className="mr-4"
                style={{ width: "10%" }}
              />
              <div>
                <img src={our_service_top_image} alt="" className="h-16" />
                <div className="z-10">
                  <p className="py-4 text-center font-normal font_blogh text-[#C75092] sm:text-5xl text-4xl  leading-[45.92px]">
                    Our Services
                  </p>
                  <p className="py-2 text-center ttMusscelsTRL text-[#FFF] sm:text-[12px] text-[10px] leading-[19.27px] font-bold uppercase">
                    Visual Research | Creative Writing | Layout Designing | AI
                    Virtual Referencing{" "}
                  </p>
                  <p className="py-2 text-center text-[#FFF] ttMusscelsTRL sm:text-[12px] text-[10px] leading-[19.27px] font-bold uppercase">
                    Brand Film Treatment Note | Feature Film Bible | Music Video
                    Lookbook{" "}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <img
                  src={our_service_rightOne_image}
                  alt=""
                  className="h-[15vh]"
                />
                <div className="h-[15vh] mx-0">
                  <img
                    src={our_service_rightTwo_image}
                    alt=""
                    className="h-[10vh]"
                  />
                  <img
                    src={our_service_arrow_image}
                    alt=""
                    className="sm:w-12 w-8 -ml-8"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="sticky bg-black-noise md:bg-white-noise shadow-md"
          id="samples"
        >
          <div className="hidden md:flex items-center p-4 bg-white-noise">
            <div className="w-[10%] relative hidden md:flex justify-center">
              <h1 className="font_blogh md:mr-0 mr-8 text text-[#F5B352] font-bold lg:text-[140px] md:text-8xl text-4xl  flip-verticle -rotate-90 scale-105 translate-x-4">
                SAMPLES
              </h1>
            </div>
            <div className="w-[40%] h-full relative md:hidden flex justify-center items-center flip-verticle -rotate-90 scale-105 translate-x-4">
              <p className="md:mr-4 mb-10 text-[#F5B352] font-bold text-[117px]">
                Categories
              </p>
            </div>
            <div className="lg:w-[90%] w-[60%] " id="categoriesUs">
              <div className="grid grid-cols-6 md:gap-10">
                <div className="col-span-6 grid grid-cols-6">
                  <Link
                    className="group relative text-[#C75092] hover:text-white  md:col-span-3 col-span-6 md:row-span-2 py-3 automotiveDiv"
                    to="/categories/Automotive"
                  >
                    <div className="automotive_left_div"></div>
                    <div className="automotive_right_div"></div>
                    <div className="automotive_top_div"></div>
                    <div className="automotive_bottom_div"></div>
                    <img
                      src={Component1}
                      alt="Automotive"
                      className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                    />
                    <p
                      className="absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      AUTOMOTIVE
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-9 bottom-1 px-4 pt-4 pb-0 right-2 md:text-5xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <Link
                    to="/categories/Narrative"
                    className="rounded-md relative text-[#4A588E] hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 narrativeDiv"
                  >
                    <div className="narrative_left_div"></div>
                    <div className="narrative_right_div"></div>
                    <div className="narrative_top_div"></div>
                    <div className="narrative_bottom_div"></div>
                    <img
                      src={Component2}
                      alt="NARRATIVE"
                      className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                    />
                    <p
                      className="absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      NARRATIVE
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-9 bottom-1 px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <Link
                    to="/categories/CGI: VFX"
                    className=" relative  text-[#F5B352] hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 cgiDiv"
                  >
                    <div className="cgi_left_div"></div>
                    <div className="cgi_right_div"></div>
                    <div className="cgi_top_div"></div>
                    <div className="cgi_bottom_div"></div>
                    <img
                      src={Component3}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                    />
                    <p
                      className="absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      CGI-VFX
                    </p>
                    <img
                      alt="pitch-perket-white-logo"
                      className="absolute md:bottom-9 bottom-1 px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                      src={whiteLogo}
                    />
                  </Link>
                  <div className="grid grid-cols-6 col-span-6 gap-4">
                    <Link
                      to="/categories/Fashion & Beauty"
                      className=" relative  text-[#4A588E] hover:text-white md:col-span-2 col-span-6 fashionDiv"
                    >
                      <div className="fashion_left_div"></div>
                      <div className="fashion_right_div"></div>
                      <div className="fashion_top_div"></div>
                      <div className="fashion_bottom_div"></div>
                      <img
                        src={Component4}
                        alt=""
                        className="sm:transform sm:scale-x-[-1] md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                      />
                      <p
                        className="absolute text-left md:bottom-1 bottom-1 px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                        style={{}}
                      >
                        FASHION
                        <br />& BEAUTY
                      </p>
                      <img
                        alt="pitch-perket-white-logo"
                        className="absolute md:bottom-16 bottom-1 px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                        src={whiteLogo}
                      />
                    </Link>
                    <Link
                      to="/categories/Food & Appliance"
                      className="relative  text-[#F5B352] hover:text-white md:col-span-2 col-span-6 foodDiv"
                    >
                      <div className="food_left_div"></div>
                      <div className="food_right_div"></div>
                      <div className="food_top_div"></div>
                      <div className="food_bottom_div"></div>
                      <img
                        src={Component5}
                        alt=""
                        className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                      />
                      <p
                        className="absolute text-left md:bottom-1 bottom-1 px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                        style={{}}
                      >
                        FOOD & APPLIANCES
                      </p>
                      <img
                        alt="pitch-perket-white-logo"
                        className="absolute md:bottom-16 bottom-1 px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                        src={whiteLogo}
                      />
                    </Link>
                    <Link
                      to="/categories/Sports & Games"
                      className="relative  text-[#C75092] hover:text-white md:col-span-2 col-span-6 sportDiv"
                    >
                      <div className="sport_left_div"></div>
                      <div className="sport_right_div"></div>
                      <div className="sport_top_div"></div>
                      <div className="sport_bottom_div"></div>
                      <img
                        src={Component6}
                        alt=""
                        className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                      />
                      <p
                        className="absolute text-left md:bottom-1 bottom-1 px-4 pt-4 pb-0 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                        style={{}}
                      >
                        SPORTS
                        <br />& GAMES
                      </p>
                      <img
                        alt="pitch-perket-white-logo"
                        className="absolute md:bottom-16 bottom-1 px-4 pt-4 pb-0 right-2 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold whitelogohover"
                        src={whiteLogo}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden flex items-center p-4 bg-black-noise">
            <div className="w-[40%] h-full relative md:hidden flex justify-center items-center flip-verticle -rotate-90 scale-105 translate-x-4">
              <p className="font_blogh ml-auto mb-10 text-[#F5B352] font-bold text-[133px]">
                {/* Categories */}
                SAMPLES
              </p>
            </div>
            <div className="lg:w-[80%] w-[60%] " id="categoriesUs">
              <div className="grid grid-cols-6 md:gap-10">
                <div className="col-span-6 grid grid-cols-6">
                  <Link
                    className="group relative text-white hover:text-white  md:col-span-3 col-span-6 md:row-span-2 py-3 automotiveDiv"
                    to="/categories/Automotive"
                  >
                    <img
                      src={sampleAutomotive}
                      alt="Automotive"
                      className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-2 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      AUTOMOTIVE
                    </p>
                  </Link>
                  <Link
                    to="/categories/Narrative"
                    className="rounded-md relative text-white hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 narrativeDiv"
                  >
                    <img
                      src={sampleNarrative}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-2 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      NARRATIVE
                    </p>
                  </Link>
                  <Link
                    to="/categories/CGI: VFX"
                    className=" relative  text-white hover:text-white md:col-span-3 col-span-6 md:ml-4 py-3 cgiDiv"
                  >
                    <img
                      src={sampleCGI}
                      alt=""
                      className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                    />
                    <p
                      className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 pb-2 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                      style={{}}
                    >
                      CGI-VFX
                    </p>
                  </Link>
                  <div className="grid grid-cols-6 col-span-6 gap-4">
                    <Link
                      to="/categories/Fashion & Beauty"
                      className=" relative  text-white hover:text-white md:col-span-2 col-span-6 fashionDiv"
                    >
                      <img
                        src={sampleFashion}
                        alt=""
                        className="md:h-full h-28 w-full border-2 border-[#4A588E] rounded-2xl"
                      />
                      <p
                        className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                        style={{}}
                      >
                        FASHION
                        <br />& BEAUTY
                      </p>
                    </Link>
                    <Link
                      to="/categories/Food & Appliance"
                      className="relative  text-white hover:text-white md:col-span-2 col-span-6 foodDiv"
                    >
                      <img
                        src={sampleFood}
                        alt=""
                        className="md:h-full h-28 w-full border-2 border-[#F5B352] rounded-2xl"
                      />
                      <p
                        className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                        style={{}}
                      >
                        FOOD & APPLIANCES
                      </p>
                    </Link>
                    <Link
                      to="/categories/Sports & Games"
                      className="relative  text-white hover:text-white md:col-span-2 col-span-6 sportDiv"
                    >
                      <img
                        src={sampleSport}
                        alt=""
                        className="md:h-full h-28 w-full border-2 border-[#C75092] rounded-2xl"
                      />
                      <p
                        className="font_blogh absolute text-left md:bottom-5 bottom-1 px-4 pt-4 left-0 lg:text-5xl md:text4xl text-2xl font-bold drop-shadow-xl font-bold w-full texthover"
                        style={{}}
                      >
                        SPORTS
                        <br />& GAMES
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sticky bg-lets_talk_bg shadow-md h-full">
          <div className="bg-pink-noice px-8 py-8 md:px-20 overflow-y-auto md:h-screen">
          </div>
        </div> */}
        <div
          className="sm:hidden block sticky bg-lets_talk_bg bg-cover bg-center bg-no-repeat shadow-md h-screen"
          ref={letsRef}
        >
          <div
            className=" gap-4 p-4 py-12"
            style={{
              backgroundImage: "url(./assets/icons/lets_talk_bg.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <p className="pb-6 text-left font-normal font_blogh text-[#FFF] text-[32px] leading-[35.2px]  uppercase">
              Feature Film & OTT
            </p>
            <p className="text-left ttMusscelsTRL text-[#FFF] text-[16px] leading-[24px] font-semibold">
              Long-form pitches demand precision in today’s multifaceted world.
              We excel in realizing your creative vision, from pre-vision to
              Unreal Engine production designs, and everything in between.
              Whether it’s a comedy, a TV series, a documentary, or an AI
              project, trust us to elevate and inspire your endeavours.
            </p>
            <a
              className="font_blogh mt-24 float-left w-max px-5 font-bold text-[16px] text-[#fff] rounded-xl p-1 border-2 border-[#fff]"
              smooth
              target="_blank"
              href="https://wa.me/+919004057055"
            >
              Let’s Talk Films
            </a>
            {/* <p className="text-center text-[#FFF] GRIFTERBold700 text-[9px] leading-[19.27px] font-bold uppercase">Brand Film Treatment Note | Feature Film Bible | Music Video Lookbook  </p> */}
          </div>
        </div>
        <div className="sticky bg-white shadow-md h-200vh" ref={compRef}>
          <div className="bg-pink-noice px-8 py-8 md:px-20 overflow-y-auto md:h-screen">
            <div className=" pb-8 text-left" id="aboutus">
              <h1 className="md:mb-4 text-[#F5B352] font_blogh  text-[24px] font-bold leading-103">
                What’s Pitch Perfekt?
              </h1>
              <p className="text-justify text-white ttMusscelsTRL text-[12px] font-bold leading-[18px] tracking-[.05em]">
                Pitch Perfekt is where creativity meets impact.{" "}
                <span className="text-[#F5B352] ttMusscelsTRL text-[12px] font-bold">
                  {" "}
                  Specializing in crafting exceptional pitch decks and treatment
                  notes,{" "}
                </span>
                we’re dedicated to turning the client’s vision into reality.
                Our team collaborates closely with clients, ensuring that each
                presentation is not just visually appealing but also rich in
                storytelling. From the initial concept to the final pitch, we
                function as an extended arm for directors, production houses &
                agencies, {" "}
                <span className="text-[#F5B352] ttMusscelsTRL text-[12px] font-bold">
                making sure that your brand & film stands out.
                </span>{" "}
                With Pitch Perfekt, you’re choosing a partner committed to
                making every moment count and every slide sell.{" "}
              </p>
            </div>
            <div className=" pb-8 text-left">
              <h1 className="text-[#F5B352] md:mb-4 font_blogh  text-[24px] font-bold">
                Why Choose Us?
              </h1>
              <p className="text-justify text-white ttMusscelsTRL text-[12px] font-bold leading-[18px]">
                Choosing Pitch Perfekt means opting for a partner who
                understands the importance of nourished storytelling and visual
                appeal in presentations. Our services are tailored to{" "}
                <span className="text-[#F5B352] ttMusscelsTRL text-[12px] font-bold">
                  showcase your vision in the brightest light,
                </span>{" "}
                ensuring that your pitches are not only seen but also
                remembered.
              </p>
            </div>
          </div>
        </div>
        <div className="sticky bg-white shadow-md h-full">
          <div className="bg-our_story_md GRIFTERBold700">
            <div className="pt-8 flex items-center justify-center">
              <p className="text-[38px]  text-[#4A588E] font_blogh font-bold">
                Our Founders
              </p>
              <img src={zigzag_blue} alt="zigzag_blue" className="w-6 -mt-8" />
            </div>
            <div className="">
              {/* <img
                alt="amir-bashir"
                src={image1}
                className="absolute fixed left-0 bottom image-filter w-[10rem] h-[12rem]"
              /> */}

              <p className=" font-semibold DemiBoldttMusscelsTRL w-100 text-[12px] text-[#4A4A4A] text-justify leading-[14.5px] px-10 z-10">
                <p
                  className="relative"
                  style={{ marginRight: "50%", width: "100%" }}
                >
                  Our founders, Amir and Nimish, have been companions on an
                  extraordinary journey through the dynamic world of filmmaking
                  and creativity. With over five years of experience in the
                  industry, their path has been one of growth, learning, and
                  shared passion.
                  <br />
                  <br />
                  From their humble beginnings as in-house Director’s Assistants
                  to their current roles as freelancers and partners, they’ve
                  embraced every challenge and opportunity with enthusiasm and
                  dedication. Nimish brings a unique flair for style and visual
                  storytelling, while Amir’s strength lies in crafting
                  compelling narratives. Their pitch decks range across various
                  categories – Narratives, Fashion & Beauty, Beverages, Food,
                  Sports, Games, Automobile, VFX and CGI.
                  <br />
                  <br />
                  {/* </p>
                <p className="px-[21%]"> */}
                  Their experience-enriched journey combined with their fervent
                  ambition for growth and perfection in the industry has led to
                  the creation of Pitch Perfekt—a culmination of their
                  collective experiences and aspirations. With a commitment to
                  excellence and a thorough understanding of industry work
                  dynamics, Pitch Perfekt aims to elevate the art of pitching to
                  new heights.
                  <br />
                  <br />
                  So, here’s to the journey ahead—to the pitches we’ll perfect,
                  the stories we’ll tell, and the magic we’ll create together.
                </p>
              </p>
              <div className="flex mt-12 justify-between">
                <div className=" flex w-full">
                  <img alt="amir" src={image1} className="w-[8rem]" />
                  <div className="-ml-12 mt-4 w-full flex flex-col">
                    <div className="flex items-center justify-start">
                      <img src={swing} alt="" className="w-12 sm:w-16" />
                    </div>
                    <div className="flex items-center justify-start md:justify-center ml-10">
                      <p className="sm:text-2xl text-xl font_blogh text-[#C75092]">
                        Amir
                      </p>
                      <img src={star} alt="star" className="-mt-8  w-3" />
                    </div>
                  </div>
                </div>
                <div className=" flex w-full">
                  <div className="-ml-24 mt-20 w-full flex flex-col">
                    <div className="flex items-center justify-end">
                      <img src={curvearrow} alt="" className="w-12 sm:w-16" />
                    </div>
                    <div className="flex  items-center justify-end md:justify-end">
                      <img
                        src={blue_ring}
                        alt="star"
                        className="-mt-8 mr-1 w-3"
                      />
                      <p className="sm:text-2xl text-xl font_blogh text-[#C75092]">
                        Nimish
                      </p>
                    </div>
                  </div>
                  <img alt="nimish" src={image2} className="w-[8rem]" />
                </div>
              </div>
              {/* <img
                alt="nimish"
                src={image2}
                className="absolute fixed right-0 bottom image-filter w-[10rem] h-[12rem]"
              /> */}
            </div>
            {/* <div className="flex pt-2">
              <div className="h-full">
                <img
                  alt="amir-bashir"
                  src={image1}
                  className="absolute fixed left-0 lg:bottom-[12%] md:bottom-[14%] sm:bottom-[16%] image-filter w-[10rem] h-[12rem]"
                />
              </div>
              <div className=" w-100 text_shadow text-[10px] text-[#FFFFFF] text-justify leading-[15px] px-24 z-10">
                <p className="font-semibold">
                  We are Amir and Nimish, companions on an extraordinary journey
                  through the dynamic world of filmmaking and creativity. With
                  over more than five years of experience in industry, our path
                  has been one of growth, learning, and shared passion.
                  <br />
                  From our humble beginnings as in-house Director's Assistants
                  to our current roles as freelancers and partners, we've
                  embraced every challenge and opportunity with enthusiasm and
                  dedication. Nimish brings a unique flair for style and visual
                  storytelling, while Amir's strength lies in crafting
                  compelling narratives. Our pitch decks range across various
                  categories - Narratives, Fashion & Beauty, Beverages, Food,
                  Sports, Games, Automobile, VFX & CGI.
                  <br />
                  <p className="px-[10%]">
                  Our journey has led us to the creation of Pitch Perfekt—a
                  culmination of our collective experiences and aspirations.
                  With a commitment to excellence and a deep understanding of
                  industry work dynamics, we aim to elevate the art of pitching
                  to new heights.                  
                  <br />
                  So here's to the journey ahead—to the pitches we'll perfect,
                  the stories we'll tell, and the magic we'll create together.
                  </p>
                </p>
              </div>
              <div className="h-full">
                <img
                  alt="nimish"
                  src={image2}
                  className="absolute right-0 fixed bottom-[8%] image-filter w-[10rem] h-[12rem]"
                />
              </div>
            </div> */}
          </div>
          {/* <div className="marquee flex font-semibold text-xl focus:scroll-auto bg-yellove text-white py-2 -mb-2">
            <div className="flex w-full gap-2">
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
            </div>
          </div> */}
        </div>
        <div className="sticky bg-white shadow-md h-full">
        <div className="marquee flex font-semibold text-xl focus:scroll-auto bg-yellove text-white py-2 -mb-2">
            <div className="flex w-full gap-2">
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
              <h1>THE SLIDE THAT SELLS .</h1>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
