// import React from "react";
// import { Link, useNavigate } from "react-router-dom";

import star from "../assets/icons/star.png";
import doublebottomarrow from "../assets/icons/Asset 15 2.svg";
import ring from "../assets/icons/Asset 19 1.svg";
import zigzag from "../assets/icons/Layer 2.svg";
import Commanfooter from "../components/commanfooter";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
// import logo from '../assets/icons/pitch-perfect.jpeg';
import logo from "../assets/icons/pitch-perfect-removebg-preview.png";
// import "./fontStyle"
import { HashLink } from "react-router-hash-link";
import FilterSidebar from "../components/model";
import { RxHamburgerMenu } from "react-icons/rx";
import { LuInstagram } from "react-icons/lu";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";

const Letstalk = () => {
  // const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [categories, setCategories] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const navigate = useNavigate();

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

  const handleFilterToggle = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };

  useEffect(() => {
    let path = location.pathname.split("/");
    if (path[1] == "categories") {
      setCategories(true);
    } else {
      setCategories(false);
    }
  }, [location.pathname]);

  const menuRef = useRef(null);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    // style={{backgroundImage: 'url(your_background_image_url.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}
    // <div className="sm:pb-20 px-12 px-4 text-black GRIFTERBold700" >
    <div className="bg-lets_talk_bg_lg bg-cover bg-center bg-no-repeat min-h-screen">
      <div>
        <div
          className={`${location.pathname === "/films" ? "block" : "block"}`}
        >
          <nav
            // white-noise
            className="bg-transparent GRIFTERBold700 px-5 md:px-12 flex lg:flex-col flex-row items-center justify-between gap-8 p-4 bg-gray-800 text-[#F5B352]"
          >
            <a href="/" className="flex items-center">
              <img src={logo} alt="Company" className="mr-4 sm:w-28 w-24" />
            </a>

            {/* Navigation links for larger screens */}
            <div className="hidden lg:flex text-xl font-bold items-center space-x-4 gap-x-6 MyFont">
              <Link
                to="/"
                className={`${
                  location.pathname === "/" ? "text-[#4A588E] " : ""
                }`}
              >
                Home
              </Link>
              <HashLink smooth to="/#categoriesUs">
                Samples
              </HashLink>
              <Link
                to="/films"
                className={` ${
                  location.pathname === "/films" ? "text-[#4A588E] " : ""
                }`}
              >
                Let’s Talk Films
              </Link>
              <Link
                to="/perfect-pitch"
                className={` ${
                  location.pathname === "/perfect-pitch"
                    ? "text-[#4A588E] "
                    : ""
                }`}
              >
                Perfekt Pitch
              </Link>
              <HashLink smooth to="/#aboutus">
                About Us
              </HashLink>
              <HashLink smooth to="/#contactus">
                Get in Touch
              </HashLink>
            </div>

            {/* Navigation links for smaller screens */}
            <div className="lg:hidden">
              <button
                onClick={handleFilterToggle}
                // className="absolute my-5  rounded-full px-2 py-2 bg-indigo-800 haver:bg-indigo-700 text-white  justify-end text-center items-center right-9 mr-2 "
                className="absolute rounded-full py-2 text-white  justify-end text-center items-center right-6 top-4"
              >
                <RxHamburgerMenu className="w-10 h-10 text-[#F5B352]"></RxHamburgerMenu>
                {/* Open Filter Sidebar */}
              </button>
              <FilterSidebar
                isOpen={isFilterSidebarOpen}
                onClose={() => setIsFilterSidebarOpen(false)}
                title=""
              >
                <div className="text-left bg-yellove absolute top-20 left-0 bg-white px-4 space-y-4 w-full">
                  <Link
                    to="/"
                    className={`block text-[#C75092]  px-1 py-2 ${
                      location.pathname === "/"
                        ? "bg-[#C75092] text-white rounded-md px-1 py-2"
                        : ""
                    }`}
                    onClick={() => {
                      setIsFilterSidebarOpen(false);
                    }}
                  >
                    Home
                  </Link>
                  <div className="">
                    <p
                      className="px-1 py-2 mt-4 text-[#C75092]"
                      onClick={() => {
                        setIsFilterSidebarOpen(false);
                        navigate("categories/Automotive");
                      }}
                    >
                      Samples
                    </p>
                  </div>
                  <Link
                    to="/film"
                    className={`block md:hidden text-[#C75092]  px-1 py-2 ${
                      location.pathname === "/films"
                        ? "bg-[#C75092] text-white rounded-2xl px-1 py-2"
                        : ""
                    }`}
                    onClick={() => {
                      setIsFilterSidebarOpen(false);
                    }}
                  >
                    Let’s Talk Films
                  </Link>
                  <Link
                    to="/films"
                    className={`hidden sm:block text-[#C75092]  px-1 py-2 ${
                      location.pathname === "/film"
                        ? "bg-[#C75092] text-white rounded-2xl px-1 py-2"
                        : ""
                    }`}
                    onClick={() => {
                      setIsFilterSidebarOpen(false);
                    }}
                  >
                    Let’s Talk Films
                  </Link>
                  {/* <Link
              to="/films"
              className={`sm:block hidden text-[#C75092]  px-1 py-2 ${
                location.pathname === "/films"
                  ? "bg-[#C75092] text-white rounded-2xl px-1 py-2"
                  : ""
              }`}
              onClick={() => {
                setIsFilterSidebarOpen(false)
              }}
            >
              Let’s Talk Films
            </Link> */}
                  <Link
                    to="/perfect-pitch"
                    className={`block text-[#C75092]  px-1 py-2  ${
                      location.pathname === "/perfect-pitch"
                        ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 "
                        : ""
                    }`}
                    onClick={() => {
                      setIsFilterSidebarOpen(false);
                    }}
                  >
                    Perfekt Pitch
                  </Link>
                  <HashLink
                    smooth
                    to="/#aboutus"
                    className={`sm:block hidden text-[#C75092]  px-1 py-2  ${
                      location.pathname === "/#aboutus"
                        ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 "
                        : ""
                    }`}
                  >
                    About Us
                  </HashLink>
                  <Link
                    to="aboutus"
                    className={`sm:hidden block text-[#C75092]  px-1 py-2  ${
                      location.pathname === "/aboutus"
                        ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 "
                        : ""
                    }`}
                    onClick={() => {
                      setIsFilterSidebarOpen(false);
                    }}
                  >
                    About Us
                  </Link>
                  <Link
                    to="contactus"
                    className={`block text-[#C75092]  px-1 py-2  ${
                      location.pathname === "/contactus"
                        ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 "
                        : ""
                    }`}
                    onClick={() => {
                      setIsFilterSidebarOpen(false);
                    }}
                  >
                    Get in Touch
                  </Link>
                  <div className="flex md:flex-row flex-col gap-4 justify-center items-center fixed bottom-2 mx-20">
                    <p className="hidden md:block text-sm">
                      @2024 by Pitch Perfekt
                    </p>
                    <div className=" flex gap-4 justify-center bottom-0">
                      <a
                        href="https://www.instagram.com/pitch.perfekt/"
                        target="_blank"
                      >
                        <LuInstagram className="w-8 h-8  text-black" />
                      </a>
                      <a
                        href="https://www.facebook.com/people/Pitch-Perfekt/61556976705845/"
                        target="_blank"
                      >
                        <BsFacebook className="w-8 h-8 text-black" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/pitchperfekt/"
                        target="_blank"
                      >
                        <FaLinkedin className="w-8 h-8  text-black" />
                      </a>

                      <a href="https://wa.me/+919004057055" target="_blank">
                        <IoLogoWhatsapp className="w-8 h-8  text-black" />
                      </a>
                    </div>
                    <p className="md:hidden text-xs">@2024 by Pitch Perfekt</p>
                  </div>
                </div>
              </FilterSidebar>
            </div>

            {/* <div className="lg:hidden" ref={menuRef}>
        <button className="text-black focus:outline-none" onClick={toggleMenu}>
          <GiHamburgerMenu size={32} color="#F5B352"/>
        </button>
        {menuOpen && (
          <div className="absolute top-0 left-0 bg-white p-4 space-y-4 w-full">
            <Link
              to="/"
              className={`block text-black ${
                location.pathname === "/" ? "border-b-2 border-white" : ""
              }`}
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/categories"
              className={`block text-black ${
                location.pathname === "/categories" ? "border-b-2 border-white" : ""
              }`}
              onClick={closeMenu}
            >
              About Us
            </Link>
            <Link
              to="/films"
              className={`block text-black ${
                location.pathname === "/films"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Let’s Talk Films
            </Link>
            <Link
              to="https://drive.google.com/file/d/1dP7UbQP2h5ex8W9ipa6MRyNxIKPz-xca/view?usp=drive_link"
              target="_blank"
              onClick={closeMenu}
              className="block text-black"
            >
              Jewels
            </Link>
            <Link
              to="/contact"
              className={`block text-black ${
                location.pathname === "/contact"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Contact
            </Link>
            <div
              className="border  bg-[#E4A795] text-black px-4 py-2 rounded hover:bg-red-300"
              // onClick={toggleLogin}
            >
              <a href="/signin">
              Login & Signup
              </a>
            </div>
          </div>
        )}
      </div> */}
          </nav>
        </div>
      </div>
      <div
        className=" sm:pb-20 px-12 px-4 text-black GRIFTERBold700"
        style={{
          backgroundImage: "url(src/assets/icons/Rectangle (3).png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" mb-40 md:mb-4">
          {/* <div className="relative hidden md:block flex flex-row-reverse right-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="31"
            viewBox="0 0 21 14"
            fill="none"
            className="absolute right-8"
          >
            <g clip-path="url(#clip0_64_2504)">
              <path
                d="M11.56 1.11008C10.9085 1.3061 10.3327 1.69696 9.91 2.23008L8.51 3.61008C7.51 4.56008 6.6 5.51008 5.66 6.47008C3.7269 8.29297 1.89737 10.2227 0.179997 12.2501C0.105428 12.3224 0.0520402 12.4137 0.0256035 12.5142C-0.000833305 12.6146 0.000685833 12.7204 0.0299967 12.8201C0.0799967 12.9401 0.119997 13.0801 0.239997 13.1401H0.299997C0.388983 13.1939 0.490999 13.2224 0.594997 13.2224C0.698995 13.2224 0.80101 13.1939 0.889997 13.1401C3.02256 11.5441 5.06252 9.82798 7 8.00008C8 7.13008 8.95 6.25008 9.92 5.37008L11.46 3.97008C12.0449 3.54669 12.4793 2.94759 12.7 2.26008C12.7415 2.10193 12.7411 1.93567 12.6987 1.77774C12.6564 1.61981 12.5735 1.47566 12.4584 1.35953C12.3433 1.2434 12.1999 1.15931 12.0423 1.11556C11.8848 1.07182 11.7185 1.06993 11.56 1.11008Z"
                fill="#3F3F3F"
              />
              <path
                d="M18.5999 0.180022C16.663 1.63901 14.8287 3.22942 13.1099 4.94002C12.2396 5.7186 11.415 6.54663 10.6399 7.42002C10.2499 7.87002 9.8599 8.33002 9.4999 8.80002C9.12731 9.29085 8.81507 9.82467 8.5699 10.39C8.53926 10.4343 8.52514 10.4879 8.52999 10.5416C8.53483 10.5952 8.55834 10.6454 8.59642 10.6835C8.63449 10.7216 8.68472 10.7451 8.73835 10.7499C8.79198 10.7548 8.84561 10.7407 8.8899 10.71C9.39799 10.4497 9.88016 10.1417 10.3299 9.79002C10.7799 9.43002 11.1999 9.06002 11.6399 8.69002C12.5699 7.93002 13.4399 7.10002 14.3399 6.31002C16.1399 4.75002 18.0899 3.41002 19.9499 1.93002C20.9199 1.14002 19.6299 -0.549978 18.5999 0.180022Z"
                fill="#3F3F3F"
              />
            </g>
            <defs>
              <clipPath id="clip0_64_2504">
                <rect width="20.29" height="13.25" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div> */}
          <div className="mb-20 flex flex-col md:px-8">
            <div className="font_blogh text-[#fff] md:ml-11 ml-4 text-left md:text-5xl text-4xl mb-2 pt-16">
              Feature Film & OTT
            </div>
            <div className="flex">
              {/* <div className="mt-2 hidden md:block">
              <img src={star} alt="" className="w-24" />
            </div> */}
              <p className="md:mx-6 mx-4 md:mt-2 ttMusscelsTRL text-[#fff] mt-6 p-2 px-4 md:text-[24px] text-[16px] md:leading-[36px] font-bold text-left">
                Long-form pitches demand precision in today’s multifaceted
                world. We excel in realizing your creative vision, from
                pre-vision to Unreal Engine production designs, and everything
                in between. Whether it’s a comedy, a TV series, a documentary,
                or an AI project, trust us to elevate and inspire your
                endeavours.
              </p>
            </div>
            <a
              className="font_blogh  md:mx-10 mx-4 mt-20 float-left w-max px-5 font-bold text-md md:text-3xl text-[#fff] rounded-md p-1 border-2 border-[#fff]"
              smooth
              target="_blank"
              href="https://wa.me/+919004057055"
            >
              Let’s Talk Films
            </a>
          </div>
        </div>
        {/* <div className="hidden md:flex justify-around my-8 h-60">
        <img src={doublebottomarrow} alt="" className="mt-32 h-12 w-12" />
        <img src={ring} alt="" className="h-12 w-12" />
        <img src={zigzag} alt="" className="mt-32 h-12 w-12" />
      </div> */}

        {/* <div className="md:pb-0 pb-20"></div> */}
      </div>
      <div className=" w-full bottom-0 text-white mb-0 py-8 2xl:absolute relative ">
        <Commanfooter />
      </div>
    </div>

    // {/* <div className="relative">
    // <div className="bg-lets_talk_bg bg-cover bg-center bg-no-repeat  sm:pb-20 px-12 px-4 text-black GRIFTERBold700 h-screen">
    // // <div className="absolute top-0 left-0 right-0 z-10">
    // //   <NavigationBar />
    // // </div>
    // <div className="text-white absolute bottom-0 left-0 right-0 z-10">
    //   <Commanfooter />
    // </div>
    // <div className="relative z-20">
    // <div className="mb-40 md:mb-4">
    //         <div className="relative hidden md:block flex flex-row-reverse right-0">
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="41"
    //             height="31"
    //             viewBox="0 0 21 14"
    //             fill="none"
    //             className="absolute right-8"
    //           >
    //             <g clip-path="url(#clip0_64_2504)">
    //               <path
    //                 d="M11.56 1.11008C10.9085 1.3061 10.3327 1.69696 9.91 2.23008L8.51 3.61008C7.51 4.56008 6.6 5.51008 5.66 6.47008C3.7269 8.29297 1.89737 10.2227 0.179997 12.2501C0.105428 12.3224 0.0520402 12.4137 0.0256035 12.5142C-0.000833305 12.6146 0.000685833 12.7204 0.0299967 12.8201C0.0799967 12.9401 0.119997 13.0801 0.239997 13.1401H0.299997C0.388983 13.1939 0.490999 13.2224 0.594997 13.2224C0.698995 13.2224 0.80101 13.1939 0.889997 13.1401C3.02256 11.5441 5.06252 9.82798 7 8.00008C8 7.13008 8.95 6.25008 9.92 5.37008L11.46 3.97008C12.0449 3.54669 12.4793 2.94759 12.7 2.26008C12.7415 2.10193 12.7411 1.93567 12.6987 1.77774C12.6564 1.61981 12.5735 1.47566 12.4584 1.35953C12.3433 1.2434 12.1999 1.15931 12.0423 1.11556C11.8848 1.07182 11.7185 1.06993 11.56 1.11008Z"
    //                 fill="#3F3F3F"
    //               />
    //               <path
    //                 d="M18.5999 0.180022C16.663 1.63901 14.8287 3.22942 13.1099 4.94002C12.2396 5.7186 11.415 6.54663 10.6399 7.42002C10.2499 7.87002 9.8599 8.33002 9.4999 8.80002C9.12731 9.29085 8.81507 9.82467 8.5699 10.39C8.53926 10.4343 8.52514 10.4879 8.52999 10.5416C8.53483 10.5952 8.55834 10.6454 8.59642 10.6835C8.63449 10.7216 8.68472 10.7451 8.73835 10.7499C8.79198 10.7548 8.84561 10.7407 8.8899 10.71C9.39799 10.4497 9.88016 10.1417 10.3299 9.79002C10.7799 9.43002 11.1999 9.06002 11.6399 8.69002C12.5699 7.93002 13.4399 7.10002 14.3399 6.31002C16.1399 4.75002 18.0899 3.41002 19.9499 1.93002C20.9199 1.14002 19.6299 -0.549978 18.5999 0.180022Z"
    //                 fill="#3F3F3F"
    //               />
    //             </g>
    //             <defs>
    //               <clipPath id="clip0_64_2504">
    //                 <rect width="20.29" height="13.25" fill="white" />
    //               </clipPath>
    //             </defs>
    //           </svg>
    //         </div>
    //         <div className="flex flex-col md:px-8">
    //           <div className="font_blogh text-[#C75092] md:ml-11 ml-4 text-left md:text-5xl text-4xl mb-2 pt-16">
    //             FEATURE FILM AND OTT PITCHES
    //           </div>
    //           <div className="flex">
    //             <div className="mt-2 hidden md:block">
    //               <img src={star} alt="" className="w-24" />
    //             </div>
    //             <p className="md:mt-2 mt-6 p-2 px-4 md:text-[22px] text-[16px] md:leading-[36px] font-bold text-left">
    //               Long-form pitches demand precision in today's multifaceted world.
    //               We excel in realizing your creative vision, from pre-vision to
    //               Unreal Engine production designs, and everything in between.
    //               Whether it's a comedy, TV series, documentary, or AI project,
    //               trust us to elevate and inspire your endeavor.
    //             </p>
    //           </div>
    //           <a
    //             className="font_blogh  md:mx-10 mx-4 mt-4 float-left w-max px-5 font-bold text-md md:text-3xl text-[#C75092] rounded-md p-1 border-2 border-[#C75092]"
    //             smooth
    //             target="_blank"
    //             href="https://wa.me/+919004057055"
    //           >
    //             Let’s Talk Films
    //           </a>
    //         </div>
    //       </div>
    //       <div className="hidden md:flex justify-around my-8 h-60">
    //         <img src={doublebottomarrow} alt="" className="mt-32 h-12 w-12" />
    //         <img src={ring} alt="" className="h-12 w-12" />
    //         <img src={zigzag} alt="" className="mt-32 h-12 w-12" />
    //       </div>
    // </div>
    // </div>
    // </div> */}
  );
};

export default Letstalk;
