import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";
import { LuInstagram } from "react-icons/lu";
import logo from "../assets/icons/pitch-perfect-removebg-preview.png";
import { IoLogoWhatsapp } from "react-icons/io";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Footer() {
  const contactUS = useRef();
  const location = useLocation();
  useEffect(() => {
    if(location.pathname == '/contactus'){
      contactUS.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  })
  return (
    <div
      id="footer-section"
      className="GRIFTERBold700 black-noise text-white pt-6 pb-8 shadow-lg"
    >
      <div className="md:px-20 px-4" id="contactus" ref={contactUS}>
      <h2 className="flex text-6xl text-left font-semibold mb-4 justify-start gap-4 mt-10">
        <div className="flex flex-col md:justify-start justify-center gap-2">
          <h2 className="font_blogh md:text-6xl text-4xl text-left font-semibold mb-4">
            Get in Touch
          </h2>
        </div>
      </h2>
      <div className="text-left flex flex-row flex-wrap justify-start md:justify-between gap-4">
        <div className="flex flex-col gap-2">
          <div>
            <p className="tracking-widest text-[#F5B352] GRIFTERBold700 sm:text-[22px] text-[18px] font-bold leading-103">
            info@pitchperfekt.in
            </p>
            <p className="tracking-widest text-white GRIFTERBold700 md:text-base text-sm font-bold leading-103">
            For Business Queries
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <p className="tracking-widest text-[#F5B352] GRIFTERBold700 sm:text-[22px] text-[18px]  font-bold leading-103">
            amir@pitchperfekt.in
            </p>
            <p className="tracking-widest text-white GRIFTERBold700 md:text-base text-sm font-bold leading-103">
            Amir Bashir Dar
            </p>
          </div>
          <div>
            <p className="tracking-widest text-[#F5B352] GRIFTERBold700 sm:text-[22px] text-[18px]  font-bold leading-103">
            nimish@pitchperfekt.in
            </p>
            <p className="tracking-widest text-white GRIFTERBold700 md:text-base text-sm font-bold leading-103">
            Nimish Poyrekar
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <p className="tracking-widest text-[#F5B352] GRIFTERBold700 sm:text-[22px] text-[18px]  font-bold leading-103">
            +91 9004057055
            </p>
            <p className="tracking-widest text-white GRIFTERBold700 md:text-base text-sm font-bold leading-103">
            Contact Us
            </p>
          </div>
        </div>
      </div>
      <div className="my-8 flex md:flex-row flex-col gap-4 justify-around items-center">
        <p className="hidden md:block text-sm">@2024 by Pitch Perfekt</p>
        <img src={logo} alt="Pitch Perfect Logo" className="w-28 h-auto mr-2" />
        <div className="flex z-50 gap-4 justify-center">
          <a href="https://www.instagram.com/pitch.perfekt/" target="_blank">
          <LuInstagram className="w-8 h-8  text-white" />
          </a>
          <a href="https://www.facebook.com/people/Pitch-Perfekt/61556976705845/" target="_blank">
          <BsFacebook className="w-8 h-8 text-white" />
          </a>
          <a href="https://www.linkedin.com/in/pitchperfekt/" target="_blank">
          <FaLinkedin className="w-8 h-8  text-white" />
          </a>
          <a href="https://wa.me/+919004057055" target="_blank">
          <IoLogoWhatsapp className="w-8 h-8  text-white" />
          </a>
        </div>
        <p className="md:hidden text-sm">@2024 by Pitch Perfekt</p>
      </div>
      </div>
      <div className="absolute -mt-[30rem] right-[40px]">
      <div className="chair-image hidden md:block" />
      </div>
    </div>
  );
}

export default Footer;
