import React from "react";
import { LuInstagram } from "react-icons/lu";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import logo from "../assets/icons/pitch-perfect-removebg-preview.png";
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

const Commanfooter = () => {
  return (
    <div className="bottom-0 my-8 mt-16 flex md:flex-row flex-col gap-4 justify-around items-center">
    <p className="hidden md:block text-sm">@2024 by Pitch Perfekt</p>
    <img src={logo} alt="Pitch Perfect Logo" className="w-28 h-auto mr-2" />
      <div className="flex gap-4">
      <a href="https://www.instagram.com/pitch.perfekt/" target="_blank">
      <LuInstagram className="w-6 h-8 "/>
      </a>
      <a href="https://www.facebook.com/people/Pitch-Perfekt/61556976705845/" target="_blank">
      <BsFacebook className="w-6 h-8"/>
      </a>
      <a href="https://www.linkedin.com/in/pitchperfekt/" target="_blank">
      <FaLinkedin className="w-6 h-8 "/>
      </a>
      <a href="https://wa.me/+919004057055" target="_blank">
          <IoLogoWhatsapp className="w-6 h-8 " />
          </a>
      </div>
      <p className="md:hidden text-sm">@2024 by Pitch Perfekt</p>
    </div>
  );
};

export default Commanfooter;
