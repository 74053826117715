import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Link, useLocation } from "react-router-dom";
// import logo from '../assets/icons/pitch-perfect.jpeg';
import logo from "../assets/icons/pitch-perfect-removebg-preview.png";
// import "./fontStyle"
import { HashLink } from "react-router-hash-link";
import FilterSidebar from "../components/model"
import { RxHamburgerMenu } from "react-icons/rx";
import { LuInstagram } from "react-icons/lu";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
// io IoLogoWhatsapp

// import logo from "../assets/icons/pitch-perfect-removebg-preview.png";

const NotNavigationBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [categories, setCategories] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const navigate = useNavigate()

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)

  const handleFilterToggle = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }
 
  useEffect(() => {
    let path = location.pathname.split('/')
    if(path[1] == 'categories'){
      setCategories(true)
    }
    else{
      setCategories(false)
    }
  }, [location.pathname])

  const menuRef = useRef(null);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);


  return (
    <div className="">   
          <div className={`${
            location.pathname === "/films" ? "hidden" : "block"
          }`}> 
    <nav
    // white-noise 
      className="bg-transparent GRIFTERBold700 px-5 md:px-12 flex lg:flex-col flex-row items-center justify-between gap-8 p-4 bg-gray-800 text-[#F5B352]"
    >
      <a href="/" className="flex items-center">
        <img src={logo} alt="Company" className="mr-4 sm:w-28 w-24" />
      </a>

      {/* Navigation links for larger screens */}
      <div className="hidden lg:flex text-xl font-bold items-center space-x-4 gap-x-6 MyFont">
        <Link
          to="/"
          className={`${
            location.pathname === "/" ? "text-[#4A588E] " : ""
          }`}
        >
          Home
        </Link>
        <HashLink smooth to="/#categoriesUs">
          Samples
        </HashLink>
        <Link
          to="/films"
          className={` ${
            location.pathname === "/films" ? "text-[#4A588E] " : ""
          }`}
        >
          Let’s Talk Films
        </Link>
        <Link
          to="perfect-pitch"
          className={` ${
            location.pathname === "/perfect-pitch" ? "text-[#4A588E] " : ""
          }`}
        >
          Perfekt Pitch
        </Link>
        <HashLink smooth to="/#aboutus">
            About Us
        </HashLink>
        <HashLink smooth to="/#contactus">
            Get in Touch
        </HashLink>
      </div>

      {/* Navigation links for smaller screens */}
      <div className="lg:hidden">
      <button
                onClick={handleFilterToggle}
                // className="absolute my-5  rounded-full px-2 py-2 bg-indigo-800 haver:bg-indigo-700 text-white  justify-end text-center items-center right-9 mr-2 "
                className="absolute rounded-full py-2 text-white  justify-end text-center items-center right-6 top-4"
              >
                <RxHamburgerMenu className="w-10 h-10 text-[#F5B352]"></RxHamburgerMenu>
                {/* Open Filter Sidebar */}
              </button>
              <FilterSidebar
                isOpen={isFilterSidebarOpen}
                onClose={() => setIsFilterSidebarOpen(false)}
                title=""
                
              >
                <div className="text-left bg-yellove absolute top-20 left-0 bg-white px-4 space-y-4 w-full">
            <Link
              to="/"
              className={`block text-[#C75092]  px-1 py-2 ${
                location.pathname === "/" ? "bg-[#C75092] text-white rounded-md px-1 py-2" : ""
              }`}
              onClick={() => {
                setIsFilterSidebarOpen(false)
              }}
            >
              Home
            </Link>
            <div className="">
            <p className="px-1 py-2 mt-4 text-[#C75092]"
              onClick={() => {
                setIsFilterSidebarOpen(false)
                navigate('categories/Automotive')
              }}
            >Samples</p>
            </div>
            <Link
              to="/film"
              className={`block sm:hidden text-[#C75092]  px-1 py-2 ${
                location.pathname === "/films"
                  ? "bg-[#C75092] text-white rounded-2xl px-1 py-2"
                  : ""
              }`}
              onClick={() => {
                setIsFilterSidebarOpen(false)
              }}
            >
              Let’s Talk Films
            </Link>
            <Link
              to="/films"
              className={`hidden sm:block text-[#C75092]  px-1 py-2 ${
                location.pathname === "/film"
                  ? "bg-[#C75092] text-white rounded-2xl px-1 py-2"
                  : ""
              }`}
              onClick={() => {
                setIsFilterSidebarOpen(false)
              }}
            >
              Let’s Talk Films
            </Link>
            <Link
          to="perfect-pitch"
          className={`block text-[#C75092]  px-1 py-2  ${
            location.pathname === "/perfect-pitch" ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 " : ""
          }`}
          onClick={() => {
            setIsFilterSidebarOpen(false)
          }}
        >
          Perfekt Pitch
        </Link>
        <HashLink smooth to="/#aboutus"
        className={`sm:block hidden text-[#C75092]  px-1 py-2  ${
          location.pathname === "/#aboutus" ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 " : ""
        }`}
        >
            About Us
        </HashLink>
        <Link
          to="aboutus"
          className={`sm:hidden block text-[#C75092]  px-1 py-2  ${
            location.pathname === "/aboutus" ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 " : ""
          }`}
          onClick={() => {
            setIsFilterSidebarOpen(false)
          }}
        >
          About Us
        </Link>
        <Link
          to="contactus"
          className={`block text-[#C75092]  px-1 py-2  ${
            location.pathname === "/contactus" ? "bg-[#C75092] text-white rounded-2xl px-1 py-2 " : ""
          }`}
          onClick={() => {
            setIsFilterSidebarOpen(false)
          }}
        >
          Get in Touch
        </Link>
        <div className="flex md:flex-row flex-col gap-4 justify-center items-center fixed bottom-2 mx-20">
        <p className="hidden md:block text-sm">@2024 by Pitch Perfekt</p>
        <div className=" flex gap-4 justify-center bottom-0">
        <a href="https://www.instagram.com/pitch.perfekt/" target="_blank">
          <LuInstagram className="w-8 h-8  text-black" />
          </a>
          <a href="https://www.facebook.com/people/Pitch-Perfekt/61556976705845/" target="_blank">
          <BsFacebook className="w-8 h-8 text-black" />
          </a>
          <a href="https://www.linkedin.com/in/pitchperfekt/" target="_blank">
          <FaLinkedin className="w-8 h-8  text-black" />
          </a>
          
          <a href="https://wa.me/+919004057055" target="_blank">
          <IoLogoWhatsapp className="w-8 h-8  text-black" />
          </a>
        </div>
        <p className="md:hidden text-xs">@2024 by Pitch Perfekt</p>
      </div>
          </div>


              </FilterSidebar>
              </div>

              
      {/* <div className="lg:hidden" ref={menuRef}>
        <button className="text-black focus:outline-none" onClick={toggleMenu}>
          <GiHamburgerMenu size={32} color="#F5B352"/>
        </button>
        {menuOpen && (
          <div className="absolute top-0 left-0 bg-white p-4 space-y-4 w-full">
            <Link
              to="/"
              className={`block text-black ${
                location.pathname === "/" ? "border-b-2 border-white" : ""
              }`}
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/categories"
              className={`block text-black ${
                location.pathname === "/categories" ? "border-b-2 border-white" : ""
              }`}
              onClick={closeMenu}
            >
              About Us
            </Link>
            <Link
              to="/films"
              className={`block text-black ${
                location.pathname === "/films"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Let’s Talk Films
            </Link>
            <Link
              to="https://drive.google.com/file/d/1dP7UbQP2h5ex8W9ipa6MRyNxIKPz-xca/view?usp=drive_link"
              target="_blank"
              onClick={closeMenu}
              className="block text-black"
            >
              Jewels
            </Link>
            <Link
              to="/contact"
              className={`block text-black ${
                location.pathname === "/contact"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Contact
            </Link>
            <div
              className="border  bg-[#E4A795] text-black px-4 py-2 rounded hover:bg-red-300"
              // onClick={toggleLogin}
            >
              <a href="/signin">
              Login & Signup
              </a>
            </div>
          </div>
        )}
      </div> */}
    </nav>
    </div>
    </div>
  );
};

export default NotNavigationBar;
