import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
// import { XMarkIcon } from '@heroicons/react/24/outline';
import { IoMdClose } from "react-icons/io";

const FilterSidebar = ({ isOpen, onClose, title, children }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={onClose}
      >
        {/* <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        > */}
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 transition-opacity ease-linear duration-1000" />
        {/* </Transition.Child> */}
        {/* <Transition.Child
          as={Fragment}
          enter="transition transform ease-in-out duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition transform ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        > */}
        <div className="bg-yellove  max-w-sm w-10/12 bg-white fixed inset-y-0 right-0 overflow-y-auto">
          <div className="flex justify-between p-4  text-white">
          <div  className="w-full py-4 text-white">
            <h1 className="text-2xl font-bold">{title}</h1>
            </div>
            <button
              className="text-2xl font-bold"
              onClick={onClose}
            >
              <IoMdClose className="h-10 w-10 text-[#C75092]" />
            </button>
            
           
          </div>
          {/* <div  className="p-4  bg-indigo-900 text-white">
            <h1 className="text-2xl font-bold">{title}</h1>
            </div> */}
          <div className="p-4">
          
            {children}
          </div>
        </div>
        {/* </Transition.Child> */}
      </Dialog>
      
    </Transition.Root>
  );
};

export default FilterSidebar;






// import React, { Fragment } from 'react';
// import { Dialog, Transition } from '@headlessui/react';
// import { XMarkIcon } from '@heroicons/react/24/outline';

// const FilterSidebar = ({ isOpen, onClose }) => {
//   return (
//     <Transition.Root show={isOpen} as={Fragment}>
//       <Dialog
//         as="div"
//         className="fixed inset-0 overflow-hidden z-50"
//         onClose={onClose}
//       >
//         <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
//         <div className="max-w-md w-full bg-white fixed inset-y-0 right-0 overflow-y-auto">
//           <div className="flex justify-end p-4">
//             <button
//               className="text-2xl font-bold"
//               onClick={onClose}
//             >
//               <XMarkIcon className="h-6 w-6" />
//             </button>
//           </div>
//           <div className="p-4">
//             <h1 className="text-2xl font-bold mb-4">Filter Sidebar</h1>
//             <p>This is the Filter sidebar content.</p>
//           </div>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   );
// };

// export default FilterSidebar;
