import React, { useEffect, useState, useRef } from "react";
import "aos/dist/aos.css";
// AUTOMOTIVE.001-min
import NARRATIVE2 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.002-min.jpeg";
import NARRATIVE3 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.003-min.jpeg";
import NARRATIVE4 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.004-min.jpeg";
import NARRATIVE5 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.005-min.jpeg";
import NARRATIVE6 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.006-min.jpeg";
import NARRATIVE7 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.007-min.jpeg";
import NARRATIVE8 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.008-min.jpeg";
import NARRATIVE9 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.009-min.jpeg";
import NARRATIVE10 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.010-min.jpeg";
import NARRATIVE11 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.011-min.jpeg";
import NARRATIVE12 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.012-min.jpeg";
import NARRATIVE13 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.013-min.jpeg";
import NARRATIVE14 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.014-min.jpeg";
import NARRATIVE15 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.015-min.jpeg";
import NARRATIVE16 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.016-min.jpeg";
import NARRATIVE17 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.017-min.jpeg";
import NARRATIVE18 from "../assets/icons/NARRATIVE/NARRATIVE/NARRATIVE.018-min.jpeg";
import Fashion2 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.002-min.jpeg";
import Fashion3 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.003-min.jpeg";
import Fashion4 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.004-min.jpeg";
import Fashion5 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.005-min.jpeg";
import Fashion6 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.006-min.jpeg";
import Fashion7 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.007-min.jpeg";
import Fashion8 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.008-min.jpeg";
import Fashion9 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.009-min.jpeg";
import Fashion10 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.010-min.jpeg";
import Fashion11 from "../assets/icons/FASHION & BEAUTY/FASHION & BEAUTY.011-min.jpeg";
// AUTOMOTIVE.001-min
import AUTOMOTIVE2 from "../assets/icons/AUTOMOTIVE/AUTOMOTIVE.002-min.jpeg";
import AUTOMOTIVE3 from "../assets/icons/AUTOMOTIVE/AUTOMOTIVE.003-min.jpeg";
import AUTOMOTIVE4 from "../assets/icons/AUTOMOTIVE/AUTOMOTIVE.004-min.jpeg";
import AUTOMOTIVE5 from "../assets/icons/AUTOMOTIVE/AUTOMOTIVE.005-min.jpeg";
import AUTOMOTIVE6 from "../assets/icons/AUTOMOTIVE/AUTOMOTIVE.006-min.jpeg";
import AUTOMOTIVE7 from "../assets/icons/AUTOMOTIVE/AUTOMOTIVE.007-min.jpeg";
import SPORTSGAMES2 from "../assets/icons/SPORTS & GAMES/SPORTS & GAMES.002-min.jpeg";
import SPORTSGAMES3 from "../assets/icons/SPORTS & GAMES/SPORTS & GAMES.003-min.jpeg";
import SPORTSGAMES4 from "../assets/icons/SPORTS & GAMES/SPORTS & GAMES.004-min.jpeg";
import SPORTSGAMES5 from "../assets/icons/SPORTS & GAMES/SPORTS & GAMES.005-min.jpeg";
import SPORTSGAMES6 from "../assets/icons/SPORTS & GAMES/SPORTS & GAMES.006-min.jpeg";
import SPORTSGAMES7 from "../assets/icons/SPORTS & GAMES/SPORTS & GAMES.007-min.jpeg";
import FOODAPPLIANCES2 from "../assets/icons/FOOD & APPLIANCES/FOOD & APPLIANCES.002-min.jpeg";
import FOODAPPLIANCES3 from "../assets/icons/FOOD & APPLIANCES/FOOD & APPLIANCES.003-min.jpeg";
import FOODAPPLIANCES4 from "../assets/icons/FOOD & APPLIANCES/FOOD & APPLIANCES.004-min.jpeg";
import FOODAPPLIANCES5 from "../assets/icons/FOOD & APPLIANCES/FOOD & APPLIANCES.005-min.jpeg";
import FOODAPPLIANCES6 from "../assets/icons/FOOD & APPLIANCES/FOOD & APPLIANCES.006-min.jpeg";
import CGI_VFX2 from "../assets/icons/CGI _ VFX/CGI _ VFX.002-min.jpeg";
import CGI_VFX3 from "../assets/icons/CGI _ VFX/CGI _ VFX.003-min.jpeg";
import CGI_VFX4 from "../assets/icons/CGI _ VFX/CGI _ VFX.004-min.jpeg";
import CGI_VFX5 from "../assets/icons/CGI _ VFX/CGI _ VFX.005-min.jpeg";
import CGI_VFX6 from "../assets/icons/CGI _ VFX/CGI _ VFX.006-min.jpeg";
import CGI_VFX7 from "../assets/icons/CGI _ VFX/CGI _ VFX.007-min.jpeg";

import CGI_VFX8 from "../assets/icons/CGI _ VFX/CGI _ VFX.008-min.jpeg";

import CGI_VFX9 from "../assets/icons/CGI _ VFX/CGI _ VFX.009-min.jpeg";


import { useLocation, useParams } from "react-router-dom";
import Commanfooter from "../components/commanfooter";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import Select from 'react-select'

const Categories = () => {
  const [imageIds] = useState([
    "Narrative",
    "Fashion & Beauty",
    "Automotive",
    "Sports & Games",
    "Food & Appliance",
    "CGI: VFX",
  ]);

  const param = useParams();
  const [selectedSection, setSelectedSection] = useState(param.path);
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const paragraphRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");
    if (section) {
      setSelectedSection(section);
    }
  }, [location.search]);

  const sectionImages = {
    Narrative: [
      NARRATIVE2,
      NARRATIVE3,
      NARRATIVE4,
      NARRATIVE5,
      NARRATIVE6,
      NARRATIVE7,
      NARRATIVE8,
      NARRATIVE9,
      NARRATIVE10,
      NARRATIVE11,
      NARRATIVE12,
      NARRATIVE13,
      NARRATIVE14,
      NARRATIVE15,
      NARRATIVE16,
      NARRATIVE17,
      NARRATIVE18,
    ],
    "Fashion & Beauty": [
      Fashion2,
      Fashion3,
      // Fashion4,
      Fashion5,
      // Fashion6,
      // Fashion7,
      Fashion8,
      Fashion9,
      Fashion10,
      Fashion11,
    ],
    Automotive: [
      AUTOMOTIVE2,
      AUTOMOTIVE3,
      // AUTOMOTIVE4,
      AUTOMOTIVE5,
      AUTOMOTIVE6,
      AUTOMOTIVE7,
    ],
    "Sports & Games": [
      SPORTSGAMES2,
      SPORTSGAMES3,
      SPORTSGAMES4,
      // SPORTSGAMES5,
      SPORTSGAMES6,
      SPORTSGAMES7,
    ],
    "Food & Appliance": [
      FOODAPPLIANCES2,
      FOODAPPLIANCES3,
      FOODAPPLIANCES4,
      FOODAPPLIANCES5,
      FOODAPPLIANCES6,
    ],
    "CGI: VFX": [CGI_VFX2, CGI_VFX3, CGI_VFX4, CGI_VFX5, CGI_VFX6,CGI_VFX7,CGI_VFX8,CGI_VFX9],
  };

  const handleSectionClick = (id) => {
    setSelectedSection(id);
  };

  const openImage = (imageUrl, index) => {
    setSelectedImage(imageUrl);
    setSelectedIndex(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
    setSelectedIndex(0);
  };

  useEffect(() => {
    paragraphRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  },[])

  const prevImage = (e) => {
    e.stopPropagation();
    const newIndex =
      (selectedIndex - 1 + sectionImages[selectedSection].length) %
      sectionImages[selectedSection].length;
    setSelectedImage(sectionImages[selectedSection][newIndex]);
    setSelectedIndex(newIndex);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    const newIndex =
      (selectedIndex + 1 + sectionImages[selectedSection].length) %
      sectionImages[selectedSection].length;
    setSelectedImage(sectionImages[selectedSection][newIndex]);
    setSelectedIndex(newIndex);
  };

  return (
    <div className="p-12 sm:pt-12 pt-4 white_background_noise" ref={paragraphRef}>
      <Navigation
        imageIds={imageIds}
        onSectionClick={handleSectionClick}
        selectedSection={selectedSection}
      />
      <div className="mt-8">
        {selectedSection && (
          <div>
            <div className="grid sm:grid-cols-3 grid grid-cols-1 items-center gap-4">
              {sectionImages[selectedSection].map((imageUrl, index) => (
                <img
                  onClick={() => openImage(imageUrl, index)}
                  key={index}
                  src={imageUrl}
                  alt={`${selectedSection} Image ${index + 1}`}
                  className="border-2 border-[#F5B352] rounded-2xl h-full w-full"
                />
              ))}
            </div>
            {selectedImage && (
              <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                onClick={closeImage}
              >
                <div
                  className="max-w-screen-lg p-4 "
                  onClick={(e) => e.stopPropagation()}
                >
                  <div></div>
                  <button
                    className="absolute inset-y-1/2 sm:left-10 left-0 m-4 bg-black text-white -mt-3"
                    onClick={prevImage}
                  >
                    <FaChevronLeft className="w-10 h-10 text-white" />
                  </button>
                  <button
                    className="absolute  inset-y-1/2 sm:right-10 right-0 m-4 bg-black text-white -mt-3"
                    onClick={nextImage}
                  >
                    <FaChevronRight className="w-10 h-10 text-white" />
                  </button>
                  <img
                    src={selectedImage}
                    alt="Selected Image"
                    className="mx-auto max-h-screen rounded-2xl border-2 border-[#F5B352]"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="GRIFTERBold700 text-left mt-16 text-[#4A588E] text-[12px] sm:text-[16px]">
          IMAGES ON THIS SITE ARE SOURCED FROM WEBSITES WITH PUBLIC ACCESS AND
          ARE ONLY INTENDED AS EXAMPLES OF VISUAL REFERENCES. ALL RIGHTS BELONG
          TO THEIR OWNERS.
        </div>
      </div>
      <Commanfooter />
    </div>
  );
};

function Navigation({ imageIds, onSectionClick, selectedSection }) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 35,
      minHeight: 15,
      borderRadius: '8px',
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? '#C75092' : '#C75092',
      '&:hover': {
        borderColor: state.isFocused ? '#C75092' : '#C75092'}
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#C75092' : '#C75092',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#C75092' : '#C75092',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#C75092' : '#F5B352',
      backgroundColor: state.isSelected ? '#F5B352' : '',
      borderRadius: '5%',

    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5%',
      backgroundColor:'#C75092'
    }),
  };
  
  return (
    <>
    <nav className="hidden lg:flex items-center justify-center p-4 bg-gray-800 text-white white_background_noise">
      <ul className="border-2 border-[#C75092] p-1 rounded-2xl hidden lg:flex items-center gap-x-4 MyFont">
        {imageIds.map((id) => (
          <li key={id}>
            <button
              onClick={() => onSectionClick(id)}
              className={
                selectedSection === id
                  ? "text-white bg-[#C75092] px-2 py-2 rounded-xl text-[18px]"
                  : "text-[#C75092] px-2 py-1 rounded-md text-[18px]"
              }
            >
              {id}
            </button>
          </li>
        ))}
      </ul>
    </nav>
    <div className="md:hidden block flex items-center justify-end">
    <label htmlFor="tabs" className="sr-only">
      Select a tab
    </label>
    <Select
      id="tabs"
      name="tabs"
      className="block mt-4 w-56"
      styles={customStyles}
      options={imageIds.map((tab) => ({
        ...tab,
        label:tab,
        value:tab
      }))}
      value={{ value: selectedSection, label: selectedSection }}
      onChange={(option) => {
        onSectionClick(option.value)
      }}
    >

    </Select>
  </div>
  </>
  );
}

export default Categories;
