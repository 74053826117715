import { useRef, useEffect, useState } from "react";
import Commanfooter from "../components/commanfooter";

const PerfectPitch = () => {
  const paragraphRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const sectionImages = [
    { imageUrl: "https://img.youtube.com/vi/HSjFA0llWIM/hqdefault.jpg", youtubeId: "HSjFA0llWIM" },
    { imageUrl: "https://img.youtube.com/vi/mFh7sJJL0kA/hqdefault.jpg", youtubeId: "mFh7sJJL0kA" },
    { imageUrl: "https://img.youtube.com/vi/rh0K7BtGDu0/hqdefault.jpg", youtubeId: "rh0K7BtGDu0" },
    { imageUrl: "https://img.youtube.com/vi/OrtPYKlcT6Q/hqdefault.jpg", youtubeId: "OrtPYKlcT6Q" },
    { imageUrl: "https://img.youtube.com/vi/2FEFf1Gy6BA/hqdefault.jpg", youtubeId: "2FEFf1Gy6BA" },
    { imageUrl: "https://img.youtube.com/vi/dM8JGiH0P7U/hqdefault.jpg", youtubeId: "dM8JGiH0P7U" },
    { imageUrl: "https://img.youtube.com/vi/IezYLVUuM9I/hqdefault.jpg", youtubeId: "IezYLVUuM9I" },
    { imageUrl: "https://img.youtube.com/vi/ZDFE0vNkoVI/hqdefault.jpg", youtubeId: "ZDFE0vNkoVI" },
    { imageUrl: "https://img.youtube.com/vi/EDnvDZ-0990/hqdefault.jpg", youtubeId: "EDnvDZ-0990" },
  ];

  useEffect(() => {
    paragraphRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <div className="p-12 white_background_noise" ref={paragraphRef}>
      <div className="mt-8">
        <div className="font_blogh text-[#C75092] text-left md:text-5xl text-4xl mb-8">
          Perfekt Pitch
        </div>
        <div>
          <div className="grid sm:grid-cols-3 grid grid-cols-1 items-center gap-4">
            {sectionImages.map((imageData, index) => (
              <a
                key={index}
                href={`https://www.youtube.com/watch?v=${imageData.youtubeId}`}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className="relative"
              >
                <img
                  src={imageData.imageUrl}
                  alt={`Image ${index + 1}`}
                  className="rounded-2xl h-full w-full shadow-sm"
                />
                {hoveredIndex === index && (
                  <div className="absolute inset-0 flex items-center justify-center shadow-sm">
                    <button className="bg-[#FF0000] text-white px-4 py-4 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                    </svg>
                    </button>
                  </div>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
      <Commanfooter />
    </div>
  );
};

export default PerfectPitch;
